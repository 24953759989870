/*eslint-disable*/
import React, { useState } from "react";
import IndexNavbarr from "components/Navbars/IndexNavbarr.js";
import Footer from "components/Footers/Footer.js";

import gallery1 from "assets/img/gallery/gallery1.webp";
import gallery2 from "assets/img/gallery/gallery2.webp";
import gallery3 from "assets/img/gallery/gallery3.webp";
import gallery4 from "assets/img/gallery/gallery4.webp";
import gallery5 from "assets/img/gallery/gallery5.webp";
import gallery6 from "assets/img/gallery/gallery6.webp";
import gallery7 from "assets/img/gallery/gallery7.webp";
import gallery8 from "assets/img/gallery/gallery8.webp";
import gallery9 from "assets/img/gallery/gallery9.webp";
import gallery10 from "assets/img/gallery/gallery10.webp";
import gallery11 from "assets/img/gallery/gallery11.webp";
import gallery12 from "assets/img/gallery/gallery12.webp";

const exteriorImages = [
  gallery3, gallery5, gallery9, gallery7, gallery6,
];

const interiorImages = [
  gallery1, gallery2, gallery8, gallery12, gallery10, gallery11, gallery4
];

export default function Index() {
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState("exterior");

  const openModal = (index) => {
    setCurrentIndex(index);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const showNextImage = () => {
    const images = selectedCategory === "exterior" ? exteriorImages : interiorImages;
    setCurrentIndex((currentIndex + 1) % images.length);
  };

  const showPreviousImage = () => {
    const images = selectedCategory === "exterior" ? exteriorImages : interiorImages;
    setCurrentIndex((currentIndex - 1 + images.length) % images.length);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setCurrentIndex(0); // Reset to the first image of the new category
  };

  const imagesToShow = selectedCategory === "exterior" ? exteriorImages : interiorImages;

  return (
    <>
      <IndexNavbarr fixed />

      <section className="xl:py-12 py-12">
        <div className="container mx-auto px-4">
          <div className="w-full xl:pt-24">
            <div className="flex flex-wrap justify-center xl:gap-4">
              <div
                className={`cursor-pointer bgbutton font-semibold rounded-lg py-2 xl:w-2/12 text-center text-white xl:text-sm font ${selectedCategory === "exterior" ? "bg-blue-500" : ""}`}
                onClick={() => handleCategoryChange("exterior")}
              >
                Exterior
              </div>
              <div
                className={`cursor-pointer  bgbutton font-semibold rounded-lg py-2 xl:w-2/12 text-center text-white xl:text-sm font ${selectedCategory === "interior" ? "bg-blue-500" : ""}`}
                onClick={() => handleCategoryChange("interior")}
              >
                Interior
              </div>
            </div>
          </div>
          <div className="flex flex-wrap py-6 ">
            {imagesToShow.map((image, index) => (
              <div key={index} className="xl:w-4/12 lg:w-4/12 md:w-6/12 px-1 py-2">
                <img
                  src={image}
                  alt=""
                  className="rounded-lg w-full h-270-px cursor-pointer"
                  onClick={() => openModal(index)}
                />
              </div>
            ))}
          </div>
        </div>
      </section>

      <Footer />

      {/* Modal */}
      {isOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div
              onClick={closeModal}
              className="modal-close"
            >
              <div className="" style={{ marginTop: "-8px", marginLeft: "5px" }}>
                &times;
              </div>
            </div>
            <div className="image-container">
              <img
                src={imagesToShow[currentIndex]}
                alt=""
                className="popup-image"
              />
            </div>

            <button
              onClick={showPreviousImage}
              className="modal-navigation modal-prev"
            >
              &#10094;
            </button>
            <button
              onClick={showNextImage}
              className="modal-navigation modal-next"
            >
              &#10095;
            </button>
          </div>
        </div>
      )}
    </>
  );
}
