/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import embassylogoo from "assets/img/embassylogoo.webp";
// components

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);

  return (
    <>
      <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-transparent">
        <div className="container px-4 py-4 mx-auto flex flex-wrap items-center justify-between">
          <div
            className={
              "lg:flex flex-grow  lg:bg-opacity-0 lg:shadow-none" +
              (navbarOpen ? " fixed top-0 left-0 w-full h-full bg-white flex justify-center  z-50" : " hidden")
            }
            id="example-navbar-warning"
          >
            {/* Close button when menu is open */}
            {navbarOpen && (
              <button
                className=" top-4 right-2 fixed text-3xl"
                onClick={() => setNavbarOpen(false)}
              >
                <i className="fas fa-times fixed right-2"></i>
              </button>
            )}

            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto text-center xl:hidden lg:hidden block xl:pt-12">
              <li className="flex items-center px-3 py-2">
                <a href="/" className="hover:text-blueGray-500 text-blueGray-700 text-base uppercase font-bold">
                  Home
                </a>
              </li>
              <li className="flex items-center px-3 py-2">
                <a href="/project-highlight" className="hover:text-blueGray-500 text-blueGray-700 text-base uppercase font-bold">
                  Project Highlight
                </a>
              </li>
              <li className="flex items-center px-3 py-2">
                <a href="/location" className="hover:text-blueGray-500 text-blueGray-700 text-base uppercase font-bold">
                  Location
                </a>
              </li>
              <li className="flex items-center px-3 py-2">
                <a href="/golden-willows" className="hover:text-blueGray-500 text-blueGray-700 text-base uppercase font-bold">
                  Golden Willows Highlight
                </a>
              </li>
              <li className="flex items-center px-3 py-2">
                <a href="/iso-3d" className="hover:text-blueGray-500 text-blueGray-700 text-base uppercase font-bold">
                  Project Details
                </a>
              </li>
              <li className="flex items-center px-3 py-2">
                <a href="/" className="hover:text-blueGray-500 text-blueGray-700 text-base uppercase font-bold">
                  Amenities
                </a>
              </li>
              <li className="flex items-center px-3 py-2">
                <a href="/gallery" className="hover:text-blueGray-500 text-blueGray-700 text-base uppercase font-bold">
                  Gallery
                </a>
              </li>
            </ul>
          </div>
          <div className="xl:w-2/12 lg:w-6/12 relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <Link
              to="/"
              className="text-blueGray-700 text-sm font-bold leading-relaxed inline-block mr-4 whitespace-nowrap uppercase"
            >
              <img src={embassylogoo} className="w-6/12 md:w-4/12 xl:w-9/12 rounded-xl" alt="Embassy Logo" style={{
                background: "#ffffff8a",
                padding: "15px"
              }} />
            </Link>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>

        </div>
      </nav>
    </>
  );
}
