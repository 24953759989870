import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

// layouts

import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";

// views without layouts

import Landing from "views/Landing.js";
import Profile from "views/Profile.js";
import Index from "views/Index.js";
import Iso from "views/Iso";
import Gallery from "views/Gallery";
import Projecthighlight from "views/Projecthighlight";
import Location from "views/Location";
import Unit2d from "views/Unit2d";
import Goldenwillows from "views/Goldenwillows";
import Tower from "views/Tower";
import Detail from "views/Detail";
import Amenities from "views/Amenities";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* add routes with layouts */}
      <Route path="/admin" component={Admin} />
      <Route path="/auth" component={Auth} />
      {/* add routes without layouts */}
      <Route path="/landing" exact component={Landing} />
      <Route path="/profile" exact component={Profile} />
      <Route path="/" exact component={Index} />
      <Route path="/iso-3d" exact component={Iso} />
      <Route path="/2d-unit" exact component={Unit2d} />
      <Route path="/gallery" exact component={Gallery} />
      <Route path="/project-highlight" exact component={Projecthighlight} />
      <Route path="/location" exact component={Location} />
      <Route path="/master-plans" exact component={Goldenwillows} />
      <Route path="/tower" exact component={Tower}/>
      <Route path="/detail" exact component={Detail}/>
      <Route path="/amenities" exact component={Amenities}/>
      {/* add redirect for first page */}
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
