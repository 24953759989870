/*eslint-disable*/
import React, { useState } from "react";
import IndexNavbarr from "components/Navbars/IndexNavbarr.js";
import "./ImageMapComponent.css";
import Footer from "components/Footers/Footer";
import gallery1 from "assets/img/gallery/gallery1.webp";
import gallery2 from "assets/img/gallery/gallery2.webp";
import gallery3 from "assets/img/gallery/gallery3.webp";
import gallery4 from "assets/img/gallery/gallery4.webp";
import gallery5 from "assets/img/gallery/gallery5.webp";
import gallery6 from "assets/img/gallery/gallery6.webp";
import gallery7 from "assets/img/gallery/gallery7.webp";
import gallery8 from "assets/img/gallery/gallery8.webp";
import gallery9 from "assets/img/gallery/gallery9.webp";
import gallery10 from "assets/img/gallery/gallery10.webp";
import gallery11 from "assets/img/gallery/gallery11.webp";
import gallery12 from "assets/img/gallery/gallery12.webp";

export default function Amenities() {
  const images = [ gallery4, gallery11, gallery6];
  const [currentIndex, setCurrentIndex] = useState(0); // State to track the current image

  // Function to handle next slide
  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    ); // Go to the next image, loop back to first if at the end
  };

  // Function to handle previous slide
  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    ); // Go to the previous image, loop back to last if at the start
  };

  return (
    <>
      <IndexNavbarr fixed />

      <section className="relative h-screen xl:py-32 lg:pt-32 md:pt-48 xl:pb-12 pt-24 bg-gray-100">
        <div className="container mx-auto flex justify-center items-center">
          <div className="flex flex-wrap justify-between lg:flex-row flex-col-reverse">
            {/* Sidebar Section */}
            <div
              className="w-full xl:w-2/12 lg:w-2/12 md:w-2/12 bg-white border rounded-lg"
              style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}
            >
              <div
                className="flex xl:flex-wrap lg:flex-wrap items-center justify-between xl:py-6"
                style={{ background: "#f0eeee" }}
              >
                <div className="pt-4 w-full px-6">
                  <div className="font text-base font-semibold text-center text-white py-2 rounded bgbutton">
                    List Of Amenities
                  </div>
                </div>
              </div>

              <div className="px-4 pb-4 xl:pt-4 xl:block lg:block hidden">
                <h1
                  className="font-bold uppercase text-sm font xl:pt-4"
                  style={{ color: "#be935f" }}
                >
                  Ground Floor
                </h1>
                <p className="xl:pt-2 font text-sm">1. Reception & Entrance Lounge</p>
                <p className="xl:pt-2 font text-sm">2. Gourmet Restaurant</p>
                <p className="xl:pt-2 font text-sm">3. Business Center</p>
                <h1
                  className="font-bold uppercase text-sm font xl:pt-4"
                  style={{ color: "#be935f" }}
                >
                  First Floor
                </h1>
                <p className="xl:pt-2 font text-sm">1. Themed SPA & Massage</p>
                <p className="xl:pt-2 font text-sm">2. Yoga Room & Meditation Den</p>
                <p className="xl:pt-2 font text-sm">3. Indoor Games</p>
                <h1
                  className="font-bold uppercase text-sm font xl:pt-4"
                  style={{ color: "#be935f" }}
                >
                  Second Floor
                </h1>
                <p className="xl:pt-2 font text-sm">1. Gymnasium</p>
                <p className="xl:pt-2 font text-sm">2. Spinning/Dance Area</p>
                <p className="xl:pt-2 font text-sm">3. Toilets</p>
              </div>
            </div>

            {/* Image Slider Section */}
            <div className="w-full xl:w-8/12 lg:w-8/12 md:w-8/12 px-6 xl:pt-0 lg:pt-0 md:pt-0 pt-6 relative">
              <div className="relative flex items-center justify-center">
                {/* Previous arrow */}
                <button
                  className="modal-navigation1 absolute left-0 z-10 top-1/2 transform -translate-y-1/2 bg-gray-600 text-white px-4 py-2 rounded-full"
                  onClick={prevSlide}
                >
                  &#10094;
                </button>

                {/* Image Display */}
                <img
                  src={images[currentIndex]}
                  alt="tower"
                  className="rounded-lg object-cover w-full h-auto"
                />

                {/* Next arrow */}
                <button
                  className="modal-navigation1 absolute right-0 z-10 top-1/2 transform -translate-y-1/2 bg-gray-600 text-white px-4 py-2 rounded-full"
                  onClick={nextSlide}
                >
                 &#10095;
                </button>
              </div>
            </div>

            {/* Right Sidebar Section */}
            <div
              className="w-full xl:w-2/12 lg:w-2/12 md:w-2/12 bg-white border rounded-lg"
              style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}
            >
              <div
                className="flex xl:flex-wrap lg:flex-wrap items-center justify-between xl:py-6"
                style={{ background: "#f0eeee" }}
              >
                <div className="pt-2 w-full xl:block lg:block block px-6">
                  <div className="font text-base font-semibold text-center text-white py-2 rounded bgbutton">
                    Club Royale
                  </div>
                </div>
              </div>

              <div className="px-4 pb-4 xl:pt-4 xl:block lg:block hidden">
                <h1
                  className="font-bold uppercase text-sm font"
                  style={{ color: "#be935f" }}
                >
                  Apartment Features
                </h1>
                <p className="xl:pt-4 font font-semibold text-sm">
                  1. Biggest Clubhouse with 90k+ sqft area
                </p>
                <p className="xl:pt-2 font font-semibold text-sm">
                  2. 20 + Lifestyle amenities
                </p>
                <p className="font text-sm">
                  Large format Vitrified tiles, anti-skid Vitrified tiles in
                  balcony and utility room
                </p>
                <p className="xl:pt-2 font font-semibold text-sm">3. Windows</p>
                <p className="font text-sm">
                  Heavy Duty aluminium Single-glazed windows
                </p>
                <p className="xl:pt-2 font font-semibold text-sm">4. Doors</p>
                <p className="font text-sm">
                  Main entrance door in veneer with melamine finish & laminate
                  on all internal doors
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
