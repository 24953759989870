/*eslint-disable*/
import React, { useEffect, useState, useRef } from "react";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer";
import gallery3 from "assets/img/gallery/gallery3.webp"


export default function Projecthighlight() {


    return (
        <>
            <IndexNavbar fixed />

            <section className="h-screen">
                <div className=""
                    style={{
                        backgroundImage: `url(${gallery3})`, backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        height: "100vh",
                    }}>
                    <div className="container mx-auto xl:pt-40 px-4">
                        <div className="w-full flex flex-wrap justify-end">
                            <div className="xl:w-3/12">

                            </div>
                            <div className="xl:w-6/12">

                            </div>
                            <div className="xl:w-3/12 bgblack-200 shadow xl:py-6 lg:py-6 py-12 px-6 rounded-lg">
                                <h2 className="underline text-white font xl:text-xl lg:text-base uppercase font-semibold">
                                    Project Overview
                                </h2>
                                <div className="flex items-center xl:pt-2 lg:pt-2 pt-2">
                                    <h3 className="items-center xl:gap-2 flex text-white xl:text-base  lg:text-sm text-sm font uppercase font-semibold">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40"><path fill="#fdaf17" d="M5.417 34.292V11.875h6.791v-6.75h15.625v13.542h6.75v15.625h-12.25v-6.75h-4.666v6.75ZM7.5 32.208h4.708v-4.666H7.5Zm0-6.791h4.708V20.75H7.5Zm0-6.75h4.708V14H7.5Zm6.792 6.75h4.666V20.75h-4.666Zm0-6.75h4.666V14h-4.666Zm0-6.792h4.666V7.208h-4.666Zm6.75 13.542h4.666V20.75h-4.666Zm0-6.75h4.666V14h-4.666Zm0-6.792h4.666V7.208h-4.666Zm6.791 20.333H32.5v-4.666h-4.667Zm0-6.791H32.5V20.75h-4.667Z"></path></svg> Plot Area
                                    </h3>
                                </div>
                                <ul className="list-disc list-inside  pl-4">
                                    <li className="text-white xl:text-sm lg:text-sm text-sm py-1">
                                        11.11 Acres
                                    </li>
                                    <li className="text-white xl:text-sm lg:text-sm text-sm py-1">
                                        85% Open space
                                    </li>
                                    <li className="text-white xl:text-sm lg:text-sm text-sm py-1">
                                        5.2 Acres of green space(50%)
                                    </li>
                                    <li className="text-white xl:text-sm lg:text-sm text-sm py-1">
                                        2.7 Acres of garden on the podium
                                    </li>
                                    <li className="text-white xl:text-sm lg:text-sm text-sm py-1">
                                        2.5 Acres of garden on the ground
                                    </li>
                                </ul>

                                <div className=" xl:pt-2 lg:pt-2 pt-2">
                                <h3 className="items-center xl:gap-2 flex text-white xl:text-base  lg:text-sm text-sm font uppercase font-semibold">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40"><path fill="#fdaf17" d="M20 31.5q-2.208 0-4.188-.792-1.979-.791-3.52-2.166l-3.542 3.5q-.208.208-.479.208t-.479-.208q-.209-.209-.209-.5 0-.292.209-.459l3.5-3.5Q9.875 26 9.104 24.042q-.771-1.959-.771-4.209 0-4.875 3.396-8.27Q15.125 8.167 20 8.167h11.667v11.666q0 4.875-3.396 8.271Q24.875 31.5 20 31.5Zm0-1.417q4.292 0 7.271-3t2.979-7.25V9.583H20q-4.292 0-7.271 2.98-2.979 2.979-2.979 7.27 0 1.917.667 3.667.666 1.75 1.833 3.083l8.875-8.833q.167-.208.458-.208.292 0 .5.208.209.208.209.479t-.209.521l-8.833 8.792q1.333 1.208 3.083 1.875 1.75.666 3.667.666Zm0-10.25Z"></path></svg>
                                     Key township Highlights
                                        
                                    </h3>
                                    {/* <h3 className="text-white xl:text-sm lg:text-sm  font uppercase font-semibold">
                                        Key township Highlights
                                        (1st in Mumbai NXT)
                                    </h3> */}
                                </div>
                                <ul className="list-disc list-inside xl:pt-2 lg:pt-2 pt-2 pl-4">
                                    <li className="text-white xl:text-sm lg:text-sm text-sm py-1">
                                        Integrated Township
                                    </li>
                                    <li className="text-white xl:text-sm lg:text-sm text-sm py-1">
                                        19 towers | 2200+ apartments delivered
                                    </li>
                                    <li className="text-white xl:text-sm lg:text-sm text-sm py-1">
                                        Fully Functional School, retail & commercial
                                    </li>
                                    <li className="text-white xl:text-sm lg:text-sm text-sm py-1">
                                        Wide roads with pedestrian pathway
                                    </li>
                                    <li className="text-white xl:text-sm lg:text-sm text-sm py-1">
                                        100 acres of open green spaces
                                    </li>
                                    <li className="text-white xl:text-sm lg:text-sm text-sm py-1">
                                        25 acres of adjacent reserve forest
                                    </li>
                                    <li className="text-white xl:text-sm lg:text-sm text-sm py-1">
                                        Hill, Forest and River view
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <Footer />
        </>
    );
}
