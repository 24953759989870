/*eslint-disable*/
import React, { useEffect, useState, useRef } from "react";

import IndexNavbarr from "components/Navbars/IndexNavbarr.js";
import './ImageMapComponent.css';
import Footer from "components/Footers/Footer";
import tower from "assets/img/tower.webp"

export default function Detail() {


    return (
        <>
            <IndexNavbarr fixed />

            <section className="relative h-screen xl:py-32 lg:pt-32 md:pt-48 xl:pb-12 pt-24 bg-gray-100">
                <div className="container mx-auto flex justify-center items-center">
                    <div className="flex flex-wrap justify-between  lg:flex-row flex-col-reverse">
                        <div className="w-full xl:w-2/12 lg:w-2/12 md:w-2/12 bg-white border rounded-lg" style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}>
                            <div className="flex xl:flex-wrap lg:flex-wrap items-center justify-between xl:py-6" style={{ background: "#f0eeee" }}>
                                <div className="pt-4 w-full px-6">
                                    <div className="font text-base font-semibold text-center text-white py-2 rounded bgbutton">
                                        Floor No : 03
                                    </div>
                                </div>
                            </div>

                            <div className="px-12 pb-4 xl:pt-4 xl:block lg:block hidden">
                            <div className="font text-sm font-semibold text-center text-white py-2 rounded-xl" style={{background: "#ff00ff"}}>
                                            Unit No. 1
                                        </div>
                                        <div className="mt-2 font text-sm font-semibold text-center text-white py-2 rounded-xl" style={{background: "#ff00ff"}}>
                                            Unit No. 2
                                        </div>
                                        <div className="mt-2 font text-sm font-semibold text-center text-white py-2 rounded-xl" style={{background: "#ff00ff"}}>
                                            Unit No. 3
                                        </div>
                                        <div className="mt-2 font text-sm font-semibold text-center text-white py-2 rounded-xl" style={{background: "#ff00ff"}}>
                                            Unit No. 4
                                        </div>
                            </div>
                        </div>

                        <div className="w-full xl:w-8/12 lg:w-8/12 md:w-8/12 px-6 xl:pt-0 lg:pt-0 md:pt-0 pt-6" >
                            <a href="/iso-3d">
                                <img src="https://hiranandanigoldenwillows.com/assets/cs/aster/Aster%20Tower%20plan.jpg" alt="tower"
                                    className="rounded-lg" />
                            </a>
                        </div>
                        <div className="w-full xl:w-2/12 lg:w-2/12 md:w-2/12 bg-white border rounded-lg" style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}>
                            <div className="flex xl:flex-wrap lg:flex-wrap items-center justify-between xl:py-6" style={{ background: "#f0eeee" }}>
                                <div className="pt-4 w-full px-6">
                                    <a href="/tower">
                                        <div className="font text-base font-semibold text-center text-white py-2 rounded bgbutton">
                                            Go Back
                                        </div>
                                    </a>
                                </div>
                                <div className="xl:pt-2 w-full px-6">
                                    <a href="/iso-3d">
                                        <div className="font text-base font-semibold text-center text-white py-2 rounded bgbutton">
                                            3D Unit Plan
                                        </div>
                                    </a>
                                </div>
                                <div className="pt-2 w-full xl:block lg:block block px-6">
                                    <div className="font text-base font-semibold text-center text-white py-2 rounded bgbutton">
                                        Zoom Image
                                    </div>
                                </div>
                            </div>

                            <div className="px-4 pb-4 xl:pt-4 xl:block lg:block hidden">
                                <h1 className="font-bold uppercase text-sm font" style={{ color: "#be935f" }}>
                                    Apartment Features
                                </h1>
                                <p className="xl:pt-4 font font-semibold text-sm">
                                    1. Homes have wide sundecks
                                </p>
                                <p className="xl:pt-2 font font-semibold text-sm">
                                    2.  Internal Flooring
                                </p>
                                <p className=" font text-sm">
                                    Large format Vitrified tiles,
                                    anti-skid Vitrified tiles
                                    in balcony and utility room
                                </p>
                                <p className="xl:pt-2 font font-semibold text-sm">
                                    3. Windows
                                </p>
                                <p className=" font text-sm">
                                    Heavy Duty aluminium
                                    Single-glazed windows
                                </p>
                                <p className="xl:pt-2 font font-semibold text-sm">
                                    4. Doors
                                </p>
                                <p className=" font text-sm">
                                    Main entrance door in veneer with
                                    melamine finish & laminate
                                    on all internal doors
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
}
