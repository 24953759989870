/*eslint-disable*/
import React, { useEffect, useState, useRef } from "react";

import IndexNavbarr from "components/Navbars/IndexNavbarr.js";
import './ImageMapComponent.css';
import Footer from "components/Footers/Footer";
import tower from "assets/img/tower.webp"

export default function Tower() {


    return (
        <>
            <IndexNavbarr fixed />

            <section className="relative h-screen xl:py-32 lg:pt-32 md:pt-48 xl:pb-12 pt-24 bg-gray-100">
                <div className="container mx-auto flex justify-center items-center">
                    <div className="flex flex-wrap justify-between  lg:flex-row flex-col-reverse">
                        <div className="w-full xl:w-2/12 lg:w-2/12 md:w-2/12 bg-white border rounded-lg" style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}>
                            <div className="flex xl:flex-wrap lg:flex-wrap items-center justify-between xl:py-6" style={{ background: "#f0eeee" }}>
                                <div className="pt-4 w-full px-6">
                                        <div className="font text-base font-semibold text-center text-white py-2 rounded bgbutton">
                                            Tower Features
                                        </div>
                                </div>
                            </div>

                            <div className="px-4 pb-4 xl:pt-4 xl:block lg:block hidden">

                                <p className="xl:pt-4 font text-sm">
                                    1. Unique neo-classical architecture
                                </p>
                                <p className="xl:pt-2 font text-sm">
                                    2. Double wall construction for sound and weatherproof homes
                                </p>
                                <p className="xl:pt-2 font  text-sm">
                                    3. Grand and spacious entrance lobby
                                </p>
                                <p className="xl:pt-2 font  text-sm">
                                    4. GEM 4 Pre-certified green building
                                </p>
                            </div>
                        </div>

                        <div className="w-full xl:w-8/12 lg:w-8/12 md:w-8/12 px-6 xl:pt-0 lg:pt-0 md:pt-0 pt-6" >
                            <a href="/detail">
                                <img src={tower} alt="tower"
                                    className="rounded-lg" />
                            </a>
                        </div>
                        <div className="w-full xl:w-2/12 lg:w-2/12 md:w-2/12 bg-white border rounded-lg" style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}>
                            <div className="flex xl:flex-wrap lg:flex-wrap items-center justify-between xl:py-6" style={{ background: "#f0eeee" }}>
                                {/* <div className="pt-4 w-full px-6">
                                    <a href="/2d-unit">
                                        <div className="font text-base font-semibold text-center text-white py-2 rounded bgbutton">
                                            2D Unit Plan
                                        </div>
                                    </a>
                                </div> */}
                                {/* <div className="xl:pt-2 w-full px-2">
                                    <div className="font text-base font-medium text-center text-white py-2 rounded bgbutton">
                                        2d Static
                                    </div>
                                </div> */}
                                <div className="pt-2 w-full xl:block lg:block block px-6">
                                    <div className="font text-base font-semibold text-center text-white py-2 rounded bgbutton">
                                        Zoom Image
                                    </div>
                                </div>
                            </div>

                            <div className="px-4 pb-4 xl:pt-4 xl:block lg:block hidden">
                                <h1 className="font-bold uppercase text-sm font" style={{ color: "#be935f" }}>
                                    Apartment Features
                                </h1>
                                <p className="xl:pt-4 font font-semibold text-sm">
                                    1. Homes have wide sundecks
                                </p>
                                <p className="xl:pt-2 font font-semibold text-sm">
                                    2.  Internal Flooring
                                </p>
                                <p className=" font text-sm">
                                    Large format Vitrified tiles,
                                    anti-skid Vitrified tiles
                                    in balcony and utility room
                                </p>
                                <p className="xl:pt-2 font font-semibold text-sm">
                                    3. Windows
                                </p>
                                <p className=" font text-sm">
                                    Heavy Duty aluminium
                                    Single-glazed windows
                                </p>
                                <p className="xl:pt-2 font font-semibold text-sm">
                                    4. Doors
                                </p>
                                <p className=" font text-sm">
                                    Main entrance door in veneer with
                                    melamine finish & laminate
                                    on all internal doors
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
}
