/*eslint-disable*/
import React, { useEffect, useState, useRef } from "react";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer";
import gallery3 from "assets/img/gallery/gallery3.webp"


export default function Projecthighlight() {


  return (
    <>
      <IndexNavbar fixed />

      <section className="h-screen">
        <div className=""
          style={{
            backgroundImage: `url(${gallery3})`, backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: "100vh",
          }}>
          <div className="container mx-auto xl:py-24 lg:py-6 py-24 px-4">
            <div className="w-full flex flex-wrap justify-center align-center">

              <div className="xl:w-3/12  shadow xl:py-12 lg:py-6 py-12 px-6 rounded-lg">

              </div>
              <div className="xl:w-6/12 xl:pt-40">
                <div className="bgblack-200 shadow xl:py-12 lg:py-6 py-12 px-6 rounded-lg">
                  <h2 className=" text-white text-center font xl:text-5xl lg:text-base uppercase font-semibold">
                    360 VR
                  </h2>
                  <p className="text-white text-center font xl:text-4xl lg:text-base uppercase font-semibold pt-2">
                    Coming Soon!
                  </p>
                </div>
              </div>
              <div className="xl:w-3/12">

              </div>
            </div>
          </div>

        </div>
      </section>

      <Footer />
    </>
  );
}
