/*eslint-disable*/
import React, { useEffect, useState, useRef } from "react";

import IndexNavbarr from "components/Navbars/IndexNavbarr.js";
import './ImageMapComponent.css';
import Footer from "components/Footers/Footer";

export default function Iso() {

    const [activeArea, setActiveArea] = useState(null);
    const [activeAreaName, setActiveAreaName] = useState('')
    const [activeElement, setActiveElement] = useState(null);
    const [imageSize, setImageSize] = useState({ width: 0, height: 0, top: 0, left: 0 });
    const [isTouchDevice, setIsTouchDevice] = useState(false);
    const imgRef = useRef(null);
    const originalWidth = 886;

    const resizeImageMap = () => {
        const img = imgRef.current;
        const map = document.querySelector('map[name="image-map"]');
        const widthRatio = img.clientWidth / originalWidth;

        map.querySelectorAll('area').forEach((area) => {
            const originalCoords = area.dataset.coords.split(',').map(Number);
            const newCoords = originalCoords.map(coord => coord * widthRatio);
            area.coords = newCoords.join(',');
        });
    };

    useEffect(() => {
        setIsTouchDevice("ontouchstart" in window || navigator.maxTouchPoints > 0);
        setActiveArea(null)
        resizeImageMap();

        window.addEventListener("resize", resizeImageMap);
        window.addEventListener("load", resizeImageMap);

        const img = document.querySelector('img[usemap]');
        if (img) {
            const updateImageSize = () => {
                const { clientWidth, clientHeight, offsetTop, offsetLeft } = img;
                setImageSize({
                    width: clientWidth,
                    height: clientHeight,
                    top: offsetTop,
                    left: offsetLeft,
                });
            }
            updateImageSize();
            window.addEventListener("load", updateImageSize);
            window.addEventListener("resize", updateImageSize);

            return () => window.removeEventListener("resize", updateImageSize);
        }


        return () => {
            window.removeEventListener("resize", resizeImageMap);
            window.removeEventListener("load", resizeImageMap);
        };
    }, [])

    const handleAreaClick = (area) => {
        setActiveArea(area.target.coords.split(',').map(Number));
        setActiveElement(area.target.id);
        setActiveAreaName(area.target.title);
    };

    const handleAreaMouseEnter = (area) => {
        if (!isTouchDevice) {
            setActiveArea(area.target.coords.split(',').map(Number));
            setActiveElement(area.target.id);
            setActiveAreaName(area.target.title);
        }
    }

    const handleAreaMouseLeave = (area) => {
        if (!isTouchDevice) {
            setActiveArea(null);
            setActiveElement(null);
            setActiveAreaName('');
        }
    }

    return (
        <>
            <IndexNavbarr fixed />

            <section className="relative h-screen xl:py-32 lg:pt-32 md:pt-48 xl:pb-12 pt-24 bg-gray-100">
                <div className="container mx-auto flex justify-center items-center">
                    <div className="flex flex-wrap justify-between  lg:flex-row flex-col-reverse">
                        <div className="w-full xl:w-2/12 lg:w-2/12 md:w-2/12 w-full  px-1 pt-2 ">
                            <div className="bg-white  rounded-lg " style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}>
                                <table className=" rounded-lg w-full py-2">
                                    <tr className="t_foyer" style={activeElement === "mainImage_1_11" ? { backgroundColor: '#e634f6a3', color: 'white' } : { backgroundColor: '#f0eeee8a' }}>
                                        <td className="py-2 font text-xs font-semibold px-2" style={{ padding: "2px;" }}>Entrance Foyer</td>
                                        <td className="tablefont py-2 font text-xs " style={{ padding: "2px;", minWidth: "70px" }}>4\'0" x 5\'3"</td>
                                    </tr>
                                    <tr className="t_living " style={activeElement === "mainImage_1_10" ? { backgroundColor: '#e634f6a3', color: 'white' } : {}}>
                                        <td className="py-2 font text-xs font-semibold px-2" style={{ padding: "2px;" }}>Living/Dining </td>
                                        <td className="tablefont py-2 font text-xs" style={{ padding: "2px;", minWidth: "70px" }}>11\'0" x 20\'0"</td>
                                    </tr>
                                    <tr className="t_balcony_1" style={activeElement === "mainImage_1_13" ? { backgroundColor: '#e634f6a3', color: 'white' } : { backgroundColor: '#f0eeee8a' }}>
                                        <td className="py-2 font text-xs font-semibold px-2" style={{ padding: "2px;" }}>Balcony</td>
                                        <td className="tablefont py-2 font text-xs " style={{ padding: "2px;", minWidth: "70px" }}>7\'9" x 5\'0"</td>
                                    </tr>
                                    <tr className="t_kitchen" style={activeElement === "mainImage_1_9" ? { backgroundColor: '#e634f6a3', color: 'white' } : {}}>
                                        <td className="py-2 font text-xs font-semibold px-2" style={{ padding: "2px;" }}>Kitchen</td>
                                        <td className="tablefont py-2 font text-xs " style={{ padding: "2px;", minWidth: "70px" }}>11\'4" x 7\'6"</td>
                                    </tr>
                                    <tr className="t_utility" style={activeElement === "mainImage_1_8" ? { backgroundColor: '#e634f6a3', color: 'white' } : { backgroundColor: '#f0eeee8a' }}>
                                        <td className="py-2 font text-xs font-semibold px-2" style={{ padding: "2px;" }}>Utility</td>
                                        <td className="tablefont py-2 font text-xs " style={{ padding: "2px;", minWidth: "70px" }}>5\'11" x 3\'10"</td>
                                    </tr>
                                    <tr className="t_bedroom_1" style={activeElement === "mainImage_1_12" ? { backgroundColor: '#e634f6a3', color: 'white' } : {}}>
                                        <td className="py-2 font text-xs font-semibold px-2" style={{ padding: "2px;" }}>Bedroom 1</td>
                                        <td className="tablefont py-2 font text-xs " style={{ padding: "2px;", minWidth: "70px" }}>10\'0" x 13\'0"</td>
                                    </tr>
                                    <tr className="t_toilet_1" style={activeElement === "mainImage_1_14" ? { backgroundColor: '#e634f6a3', color: 'white' } : { backgroundColor: '#f0eeee8a' }}>
                                        <td className="py-2 font text-xs font-semibold px-2" style={{ padding: "2px;" }}>Toilet</td>
                                        <td className="tablefont py-2 font text-xs " style={{ padding: "2px;", minWidth: "70px" }}>4\'6" × 8\'0"</td>
                                    </tr>
                                    <tr className="t_passage" style={activeElement === "mainImage_1_5" ? { backgroundColor: '#e634f6a3', color: 'white' } : {}}>
                                        <td className="py-2 font text-xs font-semibold px-2" style={{ padding: "2px;" }}>Passage</td>
                                        <td className="tablefont py-2 font text-xs " style={{ padding: "2px;", minWidth: "70px" }}>6\'9" × 3\'3"</td>
                                    </tr>
                                    <tr className="t_bedroom_2" style={activeElement === "mainImage_1_6" ? { backgroundColor: '#e634f6a3', color: 'white' } : { backgroundColor: '#f0eeee8a' }}>
                                        <td className="py-2 font text-xs font-semibold px-2" style={{ padding: "2px;" }}>Bedroom 2</td>
                                        <td className="tablefont py-2 font text-xs " style={{ padding: "2px;", minWidth: "70px" }}>10\'0"× 12\'0"</td>
                                    </tr>
                                    <tr className="t_toilet_2" style={activeElement === "mainImage_1_7" ? { backgroundColor: '#e634f6a3', color: 'white' } : {}}>
                                        <td className="py-2 font text-xs font-semibold px-2" style={{ padding: "2px;" }}> Toilet </td>
                                        <td className="tablefont py-2 font text-xs " style={{ padding: "2px;", minWidth: "70px" }}>4\'6" x 8\'0"</td>
                                    </tr>
                                    <tr className="t_master_passage" style={activeElement === "mainImage_1_4" ? { backgroundColor: '#e634f6a3', color: 'white' } : { backgroundColor: '#f0eeee8a' }}>
                                        <td className="py-2 font text-xs font-semibold px-2" style={{ padding: "2px;" }}>Master Bedroom Passage</td>
                                        <td className="tablefont py-2 font text-xs " style={{ padding: "2px;", minWidth: "70px" }}>3\'7"× 3\'3"</td>
                                    </tr>
                                    <tr className="t_master_bedroom" style={activeElement === "mainImage_1_2" ? { backgroundColor: '#e634f6a3', color: 'white' } : {}}>
                                        <td className="py-2 font text-xs font-semibold px-2" style={{ padding: "2px;" }}>Master Bedroom</td>
                                        <td className="tablefont py-2 font text-xs " style={{ padding: "2px;", minWidth: "70px" }}>11\'0" x 15\'0"</td>
                                    </tr>
                                    <tr className="t_master_toilet" style={activeElement === "mainImage_1_3" ? { backgroundColor: '#e634f6a3', color: 'white' } : { backgroundColor: '#f0eeee8a' }}>
                                        <td className="py-2 font text-xs font-semibold px-2" style={{ padding: "2px;" }}>Master Toilet</td>
                                        <td className="tablefont py-2 font text-xs " style={{ padding: "2px;", minWidth: "70px" }}>8\'0" x 4\'6"</td>
                                    </tr>
                                    <tr className="t_master_balcony" style={activeElement === "mainImage_1_1" ? { backgroundColor: '#e634f6a3', color: 'white' } : {}}>
                                        <td className="py-2 font text-xs font-semibold px-2" style={{ padding: "2px;" }}>Master Balcony</td>
                                        <td className="tablefont py-2 font text-xs " style={{ padding: "2px;", minWidth: "70px" }}>7\'0" x 4\'0"</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="px-2 pt-12 pb-16 xl:hidden lg:hidden block">
                                <h1 className="font-semibold underline text-base font">
                                    Apartment Features :
                                </h1>
                                <p className="xl:pt-4 font font-semibold text-sm">
                                    1. Homes have wide sundecks
                                </p>
                                <p className="xl:pt-2 font font-semibold text-sm">
                                    2.  Internal Flooring
                                </p>
                                <p className=" font text-sm">
                                    Large format Vitrified tiles,
                                    anti-skid Vitrified tiles
                                    in balcony and utility room
                                </p>
                                <p className="xl:pt-2 font font-semibold text-sm">
                                    3. Windows
                                </p>
                                <p className=" font text-sm">
                                    Heavy Duty aluminium
                                    Single-glazed windows
                                </p>
                                <p className="xl:pt-2 font font-semibold text-sm">
                                    4. Doors
                                </p>
                                <p className=" font text-sm">
                                    Main entrance door in veneer with
                                    melamine finish & laminate
                                    on all internal doors
                                </p>
                            </div>
                        </div>

                        <div className="w-full xl:w-8/12 lg:w-8/12 md:w-8/12 px-6 xl:pt-0 lg:pt-0 md:pt-0 pt-6" >
                            <img src="https://hiranandanigoldenwillows.com/assets/cs/aster/Aster%20Tower%20plan%20Typical_2d_1.jpg" usemap="#image-map" ref={imgRef} style={{ maxWidth: '100%', height: 'auto', width: '100%' }}
                                className="rounded-lg" />
                            <map name="image-map">
                                <area target="" alt="" href="#!" data-coords="204,94,200,120,278,123,282,94" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_1" title="Master Balcony 7\'0&quot; &#10; x 4\'0&quot;" />
                                <area target="" alt="" href="#!" data-coords="148,237,176,123,299,124,288,238" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_2" title="Master Bedroom 11\'0&quot; &#10; x 15\'0&quot;" />
                                <area target="" alt="" href="#!" data-coords="231,287,344,288,346,241,242,241" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_3" title="Master Toilet 8\'0&quot; &#10; x 4\'6&quot;" />
                                <area target="" alt="" href="#!" data-coords="290,238,334,239,336,212,292,210" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_4" title="Master Bedroom Passage 3\'7&quot; &#10; x 3\'3&quot;" />
                                <area target="" alt="" href="#!" data-coords="336,238,416,239,416,209,336,210" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_5" title="Passage 6\'9&quot; &#10; x 3\'3&quot;" />
                                <area target="" alt="" href="#!" data-coords="292,208,416,208,415,120,303,118" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_6" title="Bedroom 2 10\'0&quot; &#10; x 12\'0&quot;" />
                                <area target="" alt="" href="#!" data-coords="342,316,412,317,408,240,349,240" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_7" title="Toilet 4\'6&quot; &#10; x 8\'0&quot;" />
                                <area target="" alt="" href="#!" data-coords="376,363,460,365,459,305,420,303,421,320,376,321" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_8" title="Utility 5\'11&quot; &#10; x 3\'10&quot;" />
                                <area target="" alt="" href="#!" data-coords="462,382,634,385,618,302,460,302" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_9" title="Kitchen 11\'4&quot; &#10; x 7\'6&quot;" />
                                <area target="" alt="" href="#!" data-coords="420,298,416,138,433,139,520,136,536,138,556,250,559,302" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_10" title="Living/Dining 11\'0&quot; &#10; x 20\'0&quot;" />
                                <area target="" alt="" href="#!" data-coords="607,252,556,251,560,301,617,303" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_11" title="Entrance Foyer 4\'0&quot; &#10; x 5\'3&quot;" />
                                <area target="" alt="" href="#!" data-coords="542,146,660,147,684,249,557,248" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_12" title=" Bedroom 1 10\'0&quot; &#10; x 13\'0&quot;" />
                                <area target="" alt="" href="#!" data-coords="431,102,436,137,522,134,516,102" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_13" title=" Balcony 7\'9&quot; &#10; x 5\'0&quot;" />
                                <area target="" alt="" href="#!" data-coords="682,232,745,235,722,166,666,166" shape="poly" onClick={isTouchDevice ? handleAreaClick : undefined} onMouseLeave={!isTouchDevice ? handleAreaMouseLeave : undefined} onMouseEnter={!isTouchDevice ? handleAreaMouseEnter : undefined} id="mainImage_1_14" title=" Toilet 4\'6&quot; &#10; x 8\'0&quot;" />
                            </map>
                            {activeArea && (
                                <svg
                                    className="absolute pointer-events-none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={imageSize.width}
                                    height={imageSize.height}
                                    style={{
                                        top: `${imageSize.top}px`,
                                        left: `${imageSize.left}px`
                                    }}
                                >
                                    <polygon
                                        className="highlight"
                                        points={activeArea.join(',')}
                                    />
                                    <rect
                                        x={Math.max(...activeArea.filter((_, index) => index % 2 === 0)) + 5}
                                        y={Math.min(...activeArea.filter((_, index) => index % 2 !== 0)) - 25}
                                        width={activeAreaName.length * 9}
                                        height="20"
                                        fill="black"
                                    />
                                    <text
                                        x={Math.max(...activeArea.filter((_, index) => index % 2 === 0)) + 10}
                                        y={Math.min(...activeArea.filter((_, index) => index % 2 !== 0)) - 10}
                                        fill="white"
                                        fontSize="14"
                                        fontFamily="Arial, sans-serif"
                                        textAnchor="start"
                                        dominantBaseline="middle"
                                        style={{
                                            backgroundColor: 'black',
                                            padding: '5px',
                                        }}
                                    >
                                        {activeAreaName}
                                    </text>
                                </svg>
                            )}
                        </div>
                        <div className="w-full xl:w-2/12 lg:w-2/12 md:w-2/12 bg-white border rounded-lg" style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}>
                            <div className="flex xl:flex-wrap lg:flex-wrap items-center justify-between xl:py-6" style={{ background: "#f0eeee" }}>
                                <div className="pt-4 w-full px-6">
                                    <a href="/iso-3d">
                                        <div className="font text-base font-semibold text-center text-white py-2 rounded bgbutton">
                                            Go Back
                                        </div>
                                    </a>
                                </div>

                                {/* <div className="mt-2 pt-4 w-full px-6">
                                    <a href="/2d-unit">
                                        <div className="font text-base font-semibold text-center text-white py-2 rounded bgbutton">
                                            2D Unit Plan
                                        </div>
                                    </a>
                                </div> */}
                                {/* <div className="xl:pt-2 w-full px-2">
                                    <div className="font text-base font-medium text-center text-white py-2 rounded bgbutton">
                                        2d Static
                                    </div>
                                </div> */}
                                <div className="pt-2 w-full xl:block lg:block block px-6">
                                    <div className="font text-base font-semibold text-center text-white py-2 rounded bgbutton">
                                        Zoom Image
                                    </div>
                                </div>
                            </div>

                            <div className="px-4 pb-4 xl:pt-4 xl:block lg:block hidden">
                                <h1 className="font-bold uppercase text-sm font" style={{ color: "#be935f" }}>
                                    Apartment Features
                                </h1>
                                <p className="xl:pt-4 font font-semibold text-sm">
                                    1. Homes have wide sundecks
                                </p>
                                <p className="xl:pt-2 font font-semibold text-sm">
                                    2.  Internal Flooring
                                </p>
                                <p className=" font text-sm">
                                    Large format Vitrified tiles,
                                    anti-skid Vitrified tiles
                                    in balcony and utility room
                                </p>
                                <p className="xl:pt-2 font font-semibold text-sm">
                                    3. Windows
                                </p>
                                <p className=" font text-sm">
                                    Heavy Duty aluminium
                                    Single-glazed windows
                                </p>
                                <p className="xl:pt-2 font font-semibold text-sm">
                                    4. Doors
                                </p>
                                <p className=" font text-sm">
                                    Main entrance door in veneer with
                                    melamine finish & laminate
                                    on all internal doors
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
}


